import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

// components:
import Navbar from './components/Navbar.js';
import About from './components/About.js';
import Portfolio from './components/Portfolio.js';
import Contact from './components/Contact.js';
import PortfolioItem from './components/PortfolioItem.js';



function App() {
  return (
    <Router>


      <div className="outterFrame">

        <main className='consoleDisplay'>
          <header id='mainHeader'>

            <div className='heading-container'>
              <h1>Faheem Khan - Full-Stack Web Developer</h1>
            </div>

            <Navbar />

          </header>

          <div className='consoleContentContainer'>

            <Routes>


              <Route
                exact path='/'
                element={
                  <Portfolio />
                } />

              {/* PORTFOLIO PROJECTS */}
              {/* Maple Forest */}
              <Route
                exact path='/portfolio/maple-forest-family-physicians'
                element={
                  <PortfolioItem
                    title={"Maple Forest Family Physicians"}
                    image={"/images/MapleForest.jpg"}
                    description={<>The official website for the <strong>Maple Forest Family Physicians</strong> family medicine clinic. <br />
                      This is a full stack application which includes an admin dashboard to allow the company owners and managers to easily update the content on the website when needed.</>}
                    siteLink={`https://www.mapleforestfamilyphysicians.ca/`}
                    technologies={`JavaScript, NodeJS, Express, HTML5, CSS3, MongoDB, Mongoose`}
                  />
                } />

              {/* Lauren Nesbitt-Baggerman */}
              <Route
                exact path='/portfolio/lauren-nesbitt-baggerman'
                element={
                  <PortfolioItem
                    title={"Lauren Nesbitt-Baggerman"}
                    image={"/images/AmongThieves.jpg"}
                    description={<>The official website for <strong>Lauren Nesbitt-Baggerman</strong>, author of the Among Thieves book series. <br />
                      This is a front-end website made using HTML, CSS, and JavaScript to advertise the book series.</>}
                    siteLink={`https://amongthievesmysteries.ca/`}
                    technologies={`HTML5, CSS3, JavaScript, Bootstrap`}
                  />
                } />

              {/* TouchTone Electronics */}
              <Route
                exact path='/portfolio/touchtone-electronics'
                element={
                  <PortfolioItem
                    title={"TouchTone Electronics"}
                    image={"/images/TouchTone.jpg"}
                    description={<>The official website for <strong>TouchTone Electronics</strong>, an electronics retail store in Mississauga. <br />
                      This is a front-end website made using HTML, CSS, and JavaScript to advertise the store and their services.</>}
                    siteLink={`https://touchtoneelectronics.ca/`}
                    technologies={`HTML5, CSS3, JavaScript, Bootstrap`}
                  />
                } />

              {/* Visit Hyrule */}
              <Route
                exact path='/portfolio/visit-hyrule'
                element={
                  <PortfolioItem
                    title={"Visit Hyrule"}
                    image={"/images/VisitHyrule.jpg"}
                    description={<>A Parody travel site to the fictional land of Hyrule. <br />
                      This is a full MERN stack application, as well as a Single Page Application, using a separate client application made with React, and a server using RESTful APIs. <br />
                      <i>The Legend of Zelda: Breath of the Wild, The Legend of Zelda series, and all related characters are property of Nintendo, and their respective owners.</i></>}
                    siteLink={`https://visithyrule.vercel.app/`}
                    technologies={`JavaScript, NodeJS, Express, React, HTML5, CSS3, MongoDB, Mongoose`}
                  />
                } />

              {/* Freddit */}
              <Route
                exact path='/portfolio/freddit'
                element={
                  <PortfolioItem
                    title={"Freddit"}
                    image={"/images/Freddit.jpg"}
                    description={<>A Reddit-like app where users can create accounts and then make Subfreddits (like Subreddits) and then create posts and comment on posts in Subfreddits. <br />
                      This is a full-stack application using server-side routing and uses Passport.js for user authentication.</>}
                    siteLink={`https://freddit.onrender.com/`}
                    technologies={`JavaScript, NodeJS, Express, HTML5, CSS3, Bootstrap, MongoDB`}
                  />
                } />

              {/* Sonic Frontiers */}
              <Route
                exact path='/portfolio/sonic-frontiers'
                element={
                  <PortfolioItem
                    title={"Sonic Frontiers"}
                    image={"/images/SonicFrontiers.jpg"}
                    description={<>A Fan-made website for the Sonic Frontiers videogame to share news and details before the game's launch. <br />
                      This is a full-stack application, Single Page Application (SPA), which uses client-side routing with React. This site’s server utilizes RESTful API and data is stored in a MySQL database. <br />
                      <i>Sonic Frontiers, Sonic the Hedgehog and all related characters are property of Sega, Sonic Team, and their respective owners.</i></>}
                    siteLink={`https://sonicfrontiers.vercel.app/`}
                    technologies={`JavaScript, NodeJS, Express, React, HTML5, CSS3, Bootstrap, MySQL`}
                  />
                } />

              {/* YelpCamp */}
              <Route
                exact path='/portfolio/yelp-camp'
                element={
                  <PortfolioItem
                    title={"YelpCamp"}
                    image={"/images/YelpCamp.jpg"}
                    description={<>The final project I made in Colt Steele's Web Developer Bootcamp. A Full-Stack application which allows users to create an account and post about campgrounds and review other campgrounds</>}
                    siteLink={`https://yelpcamp-e6xz.onrender.com/`}
                    technologies={`JavaScript, NodeJS, Express, HTML5, CSS3, Bootstrap, MongoDB`}
                  />
                } />


              {/* ABOUT AND CONTACT */}
              <Route
                exact path='/About'
                element={
                  <About />
                } />

              <Route
                exact path='/contact'
                element={
                  <Contact />
                } />

              {/* redirect back to homepage if go to undefined page */}
              <Route
                path='*'
                element={
                  <Navigate to="/" replace />
                } />

            </Routes>

          </div>

        </main>


        &copy;Faheem Khan
      </div>


    </Router>

  );
}

export default App;
