import React from 'react'
import { Link } from 'react-router-dom';
import '../App.css'
import './About.css'


const About = () => {
    return (
        <section className='console-about-content'>

            <div className='about-heading'>

                <h2 >About</h2>

            </div>

            <div className='about-text'>

                <p>Full-Stack Web Developer with experience building and optimising full-stack websites and applications. Proficient using various technologies including JavaScript, NodeJS, Express, Passport, HTML5, CSS3, React, JSX, EJS, MongoDB, SQL, and using MERN stack. Successfully delivered Single Page Applications (SPAs) in an Agile environment to clients. <br /><br />See <Link to={"/"} className='external-link'>Portfolio</Link> to find examples of some of my work
                </p>

            </div>


        </section >
    )
}

export default About