import React from 'react'
import { useState, useEffect } from 'react';

import '../App.css'
import './Portfolio.css'
import { Link } from 'react-router-dom';

const Portfolio = () => {

    //checking if the component is hovered on to play audio
    const [isHovered, setIsHovered] = useState(false);

    //mouse enter event (is hover)
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    //mouse leave event (is NOT hover)
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        // Set the initial volume to 0.5 when the component mounts

        const audioElements = document.querySelectorAll('.hover-sound');
        audioElements.forEach((audio) => {
            audio.volume = 0.1;
            console.log(audio);
            console.log("test");
        });
    }, []);


    return (

        <section className='console-portfolio-content'>

            <div className='portfolio-heading'>

                <h2 >Portfolio</h2>

            </div>

            <h3>Professional Work:</h3>

            <div className='portfolio-row'>

                <Link to={'/portfolio/maple-forest-family-physicians'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/MapleForest.jpg' alt='Maple Forest Family Physician website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>

                    )}
                </Link>

                <Link to={'/portfolio/lauren-nesbitt-baggerman'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/AmongThieves.jpg' alt='Among Thieves website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>
                    )}

                </Link>

            </div>

            <div className='portfolio-row'>

                <Link to={'/portfolio/touchtone-electronics'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/TouchTone.jpg' alt='TouchTone Electronics website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>

                    )}
                </Link>

            </div>


            <h3>Personal Projects:</h3>



            <div className='portfolio-row'>

                <Link to={'/portfolio/visit-hyrule'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/VisitHyrule.jpg' alt='Visit Hyrule website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>
                    )}

                </Link>

                <Link to={'/portfolio/freddit'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/Freddit.jpg' alt='Freddit website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>
                    )}

                </Link>

            </div>

            <div className='portfolio-row'>

                <Link to={'/portfolio/sonic-frontiers'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/SonicFrontiers.jpg' alt='Sonic Frontiers Fan Site'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>
                    )}

                </Link>

                <Link to={'/portfolio/yelp-camp'} className='portfolio-row-item' onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <img className='portfolio-img' src='images/YelpCamp.jpg' alt='YelpCamp website'></img>

                    {isHovered && (
                        <audio className="hover-sound" autoPlay>
                            <source src="audio/hover.mp3" type="audio/mpeg" />
                        </audio>
                    )}

                </Link>

            </div>


        </section>

    )
}

export default Portfolio