import React from 'react'
import { FaLinkedin } from "react-icons/fa6";
import { FaSquareGithub } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";

import '../App.css'
import './Contact.css'

const Contact = () => {
    return (
        <section className='console-contact-content'>

            <div className='contact-heading'>
                <h2 >Contact</h2>
            </div>

            <div className='contact-text'>

                <div className='contact-item'>
                    <a className='external-link' href='https://www.linkedin.com/in/f-s-khan/' target='_blank' rel="noreferrer"><FaLinkedin />LinkedIn</a>
                </div>

                <div className='contact-item'>
                    <a className='external-link' href='https://github.com/FaheemK7' target='_blank' rel="noreferrer"><FaSquareGithub />GitHub</a>
                </div>

                <div className='contact-item'>
                    <a className='external-link contact-email' href='mailto:faheem@tritrix.ca'><FaEnvelope />faheem@tritrix.ca</a>
                </div>

            </div>



        </section >
    )
}

export default Contact