import React from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Navbar = () => {

    //checking if the component is hovered on to play audio
    const [isHovered, setIsHovered] = useState(false);

    //mouse enter event (is hover)
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    //mouse leave event (is NOT hover)
    const handleMouseLeave = () => {
        setIsHovered(false);
    };



    return (
        <nav id='mainNav'>

            <Link to={'/'} className='nav-item' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                Portfolio

                {isHovered && (
                    <audio className="hover-sound" autoPlay>
                        <source src="audio/hover.mp3" type="audio/mpeg" />
                    </audio>
                )}
            </Link>


            <Link to={'/about'} className='nav-item' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                About

                {isHovered && (
                    <audio className="hover-sound" autoPlay>
                        <source src="audio/hover.mp3" type="audio/mpeg" />
                    </audio>
                )}
            </Link>

            <Link to={'/contact'} className='nav-item' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                Contact

                {isHovered && (
                    <audio className="hover-sound" autoPlay>
                        <source src="audio/hover.mp3" type="audio/mpeg" />
                    </audio>
                )}
            </Link>

        </nav>
    )
}

export default Navbar