import React from 'react'
import "./PortfolioItem.css"

const PortfolioItem = (props) => {
    return (

        <div className='portfolio-item-container'>
            <h2>{props.title}</h2>
            <div className='portfolio-item-img'>
                <img src={props.image} alt='website'></img>
            </div>

            <p>{props.description}</p>

            <a href={props.siteLink} target='_blank' rel="noreferrer" className='external-link'>Visit {props.title}</a>

            <p>Technologies: <strong>{props.technologies}</strong></p>

        </div>

    )
}

export default PortfolioItem